import {
  TArticle,
  TCategory,
  TLocales,
  TNavbarDataItem,
  TUxCoreItem,
} from '@local-types/data';
import navbarData from '@data/navbar';

const managmentToolName = {
  ru: '> Статьи',
  en: '> Articles',
};
const companyManagmentName = {
  ru: 'Управление Компанией',
  en: 'Company Management',
};

export const getMyInfo = async () => {
  const myInfoUrl: string = `${process.env.NEXT_PUBLIC_STRAPI}/api/users/me`;
  const token: string = localStorage?.getItem('accessToken');

  if (token) {
    try {
      const data = await fetch(myInfoUrl, {
        headers: { Authorization: `Bearer ${token}` },
      }).then(resp => resp.json());

      if (data.error) {
        const { status, message } = data.error;
        throw new Error(`Error ${status} \n ${message}`);
      }

      return data;
    } catch (e) {
      console.error(e);
      window.localStorage.removeItem('accessToken');
    }
  }
};

export const getArticles = async (locale: TLocales) => {
  const articleUrl = `${process.env.NEXT_PUBLIC_STRAPI}/api/articles?locale=${locale}&populate=category&sort=sequence_number`;
  const articles: TArticle[] = await fetch(articleUrl)
    .then(resp => resp.json())
    .then(json => json?.data || []);

  return articles;
};

export const getCategories = async (locale: TLocales) => {
  const categoryUrl = `${process.env.NEXT_PUBLIC_STRAPI}/api/categories?locale=${locale}&sort=sequence_number`;
  const categories = await fetch(categoryUrl)
    .then(resp => resp.json())
    .then(
      json =>
        json?.data?.map((category: any) => ({
          ...category,
          submenuItems: [],
        })) || null,
    );

  return categories;
};

export const getHomeData = async (locale: TLocales) => {
  const homeUrl = `${process.env.NEXT_PUBLIC_STRAPI}/api/home-page?locale=${locale}&populate[tools][populate]=icon&populate[usedBy][populate]=*&populate[projects]=*&populate[pageSeo]=*&populate[supporters][populate]=image`;
  return await fetch(homeUrl)
    .then(resp => resp.json())
    .then(json => json?.data?.attributes || null);
};

export const getCurrentArticle = (articles: TArticle[], url: string) => {
  const article = articles.find(
    ({ attributes }: any) => attributes.url === url,
  )?.attributes;
  return article || {};
};
export const modifyCategories = (
  articles: TArticle[],
  categories: TCategory[],
) => {
  articles.forEach(({ id, attributes }: TArticle) => {
    const { url, title, description, category, locale } = attributes;
    const categoryId = category?.data?.id;

    if (categoryId) {
      const category = categories.find(
        (item: TCategory) => item.id === categoryId,
      );
      const href = url.includes('http') ? url : `/${url}`;
      category.submenuItems.push({
        id,
        title,
        href,
        answers: description ? { description } : null,
      });
    } else {
      const noCategoryArticle: TCategory = {
        id: 10000 + id,
        attributes: { title, url, locale },
        submenuItems: [],
      };
      categories.push(noCategoryArticle);
    }
  });
};

export const getPaths = async () => {
  const url = `${process.env.NEXT_PUBLIC_STRAPI}/api/articles?locale=all&populate=category`;
  const articles = await fetch(url)
    .then(resp => resp.json())
    .then(json => json?.data || []);

  const filteredArticles = articles.filter(
    article => article.attributes.url !== 'company-management',
  );
  const strapiPaths = filteredArticles
    .map((article: TArticle) => ({
      params: { page: [article.attributes.url] },
      locale: article?.attributes?.locale,
    }))
    .filter(
      (path: any) =>
        !path?.params?.page[0]?.includes('http') &&
        path?.params?.page[0] !== 'uxeducation',
    );

  return strapiPaths;
};

export const getNavData = (categories: TCategory[], locale: TLocales) => {
  const result: any = [];
  let uxCoreItem: TUxCoreItem;
  // @ts-ignore
  const staticData: TNavbarDataItem[] = navbarData[locale];
  result.push(staticData[0]);
  // @ts-ignore
  result.push({ title: managmentToolName[locale], href: '', tools: [] });
  // @ts-ignore
  result.push({
    title: companyManagmentName[locale],
    href: '/company-management',
    tools: [],
  });

  categories?.forEach(({ attributes, submenuItems }: TCategory) => {
    const { title, url } = attributes;
    const subItems = [];
    const isProductManagment = [
      'Product Management',
      'Управление Продуктами',
    ].includes(title);
    const isTest = title === 'test';
    if (isTest) return;
    if (isProductManagment) {
      const productManagmentData = staticData.find(
        (item: any) => item.title === title,
      );
      subItems.push(...productManagmentData.submenuItems.slice(0, 5));
      uxCoreItem = productManagmentData.submenuItems[1];
    }

    result[1].tools.push({
      title,
      href: url && `/${url}`,
      submenuItems: [...subItems, ...submenuItems],
    });
  });

  if (uxCoreItem) {
    result.push(uxCoreItem);
  }

  return result;
};

export const getSettings = async () => {
  const url = `${process.env.NEXT_PUBLIC_STRAPI}/api/setting`;

  return await fetch(url, {
    method: 'GET',
  })
    .then(data => data.json())
    .then(data => data?.data?.attributes);
};

export const getCoverImages = async (token: string) => {
  const url = `${process.env.NEXT_PUBLIC_STRAPI}/api/cover-images?populate=*`;
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return await fetch(url, {
    headers,
    method: 'GET',
  }).then(data => data.json());
};

export const updateCoverImage = async (token, imgUrl) => {
  const url = `${process.env.NEXT_PUBLIC_STRAPI}/api/user/update-cover`;
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
  const body = JSON.stringify({
    coverUrl: imgUrl,
  });
  return await fetch(url, {
    method: 'PUT',
    headers,
    body,
  }).then(data => data.json());
};

export const getBackgroundImages = async (token: string) => {
  const url = `${process.env.NEXT_PUBLIC_STRAPI}/api/bg-images?populate=*`;
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return await fetch(url, {
    headers,
    method: 'GET',
  }).then(data => data.json());
};

export const updateBackgroundImage = async (token, imgUrl) => {
  const url = `${process.env.NEXT_PUBLIC_STRAPI}/api/user/update-bg`;
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
  const body = JSON.stringify({
    bgUrl: imgUrl,
  });
  return await fetch(url, {
    method: 'PUT',
    headers,
    body,
  }).then(data => data.json());
};

export const getPyramids = async (locale: TLocales) => {
  const url = `${process.env.NEXT_PUBLIC_STRAPI}/api/pyramids?locale=${locale}&populate=*`;
  return await fetch(url, {
    method: 'GET',
  }).then(data => data.json());
};

export const getCompanyManagementData = async (locale: TLocales) => {
  const url = `${process.env.NEXT_PUBLIC_STRAPI}/api/company-management?locale=${locale}`;
  return await fetch(url, {
    method: 'GET',
  }).then(data => data.json());
};

export const getPyramidStats = async (locale: TLocales) => {
  const url = `${process.env.NEXT_PUBLIC_STRAPI}/api/pyramid-stats?locale=${locale}&populate%5Bstatistics%5D%5Bpopulate%5D=tooltip`;
  return await fetch(url, {
    method: 'GET',
  }).then(data => data.json());
};
